import React from "react";
import MainLayout from "../components/layout/main";
import SEO from "../components/SEO";
import { MainHeading } from "../components/headings";
import {
  Icon, Input, InputGroup, InputRightElement, Text, useDisclosure,
} from "@chakra-ui/core";
import Search from "../components/search";
import { useColorWithoutMode } from "../util/colorMode";
import { useIntl } from "gatsby-plugin-intl";

function IndexPage() {
  const intl = useIntl();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const { render } = useColorWithoutMode();
  const title = intl.formatMessage({ id: "notfound.title" });
  return (
    <MainLayout>
      <SEO
        title={title}
        description={intl.formatMessage({ id: "notfound.description" })}
      />
      <MainHeading textAlign="center" ref={finalRef}>
        {title}
      </MainHeading>
      <Text fontSize="xl" textAlign="center" pb={2}>
        <span role="img" aria-label="embarrassing">😳</span>
        {intl.formatMessage({ id: "notfound.content1" })}
      </Text>
      <Text fontSize="sm" textAlign="center">
        {intl.formatMessage({ id: "notfound.content2" })}
        <span role="img" aria-label="search">🔎</span>.
      </Text>
      {render && (
        <InputGroup maxWidth="33rem" width="100%" mt={20} mb={10} mx="auto">
          <Input
            onFocus={onOpen}
            variant="filled"
            placeholder={intl.formatMessage({ id: "notfound.type" })}
            size="xl"
          />
          <InputRightElement children={<Icon name="search" />} />
        </InputGroup>
      )}
      <Search isOpen={isOpen} onClose={onClose} finalRef={finalRef} />
    </MainLayout>
  );
}

export default IndexPage;
