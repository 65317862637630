import React from "react";
import * as PropTypes from "prop-types";
import { Box, Flex } from "@chakra-ui/core";
import Header from "../header";
import Footer from "../footer";

const MainLayout = ({ children, backgroundColor, headContent, ...rest }) => {
  // const { colorMode } = useColorMode();
  // const defaultBackground = { light: "#FFF", dark: "grey.800" };
  return (
    <>
      <Header/>
      {!!headContent && (
        <Box mt={16}>
          {headContent}
        </Box>
      )}
      <Flex align="center" justify="center" mt={!!headContent ? 0 : 16} {...rest}>
        <Box
          m={4}
          pb={8}
          width="100%"
          maxWidth={[
            "100%", // base
            "500px", // 480px upwards
            "700px", // 768px upwards
            "900px", // 992px upwards
          ]}
        >
          {children}
        </Box>
      </Flex>
      <Footer/>
    </>
  );
}

MainLayout.defaultProps = {
  headContent: null,
  backgroundColor: null,
};

MainLayout.propTypes = {
  headContent: PropTypes.node,
  backgroundColor: PropTypes.string,
};

export default MainLayout;
